import { AuthContextProps, AuthProviderProps } from 'react-oidc-context';
import { User, WebStorageStateStore } from 'oidc-client-ts';
import request from '@/request/request';

const REDIRECT_TO_NAME = 'redirectTo';

const urls = {
    get: '/auth',
};

export function getToken() {
    return request.get<string>(urls.get).then(res => res.data);
}

export function signIn(auth: AuthContextProps) {
    localStorage.setItem(REDIRECT_TO_NAME, window.location.pathname);
    return auth.signinRedirect();
}

export function getOidcConfig() {
    const authority = process.env.NEXT_PUBLIC_COGNITO_AUTHORITY as string;
    const clientId = process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID as string;
    const region = process.env.NEXT_PUBLIC_COGNITO_REGION as string;
    const poolId = process.env.NEXT_PUBLIC_COGNITO_POOL_ID as string;
    const origin = process.env.NEXT_PUBLIC_CLIENT_URL as string;

    const oidcConfig: AuthProviderProps = {
        authority: authority,
        client_id: clientId,
        redirect_uri: origin,
        scope: 'openid profile email',
        response_type: 'code',
        loadUserInfo: false,
        onSigninCallback: (_user: User | void): void => {
            const redirectTo = localStorage.getItem(REDIRECT_TO_NAME);

            if (redirectTo) {
                localStorage.removeItem(REDIRECT_TO_NAME);
                window.location.replace(redirectTo);
            } else {
                window.history.replaceState(
                    {},
                    document.title,
                    redirectTo || window.location.pathname
                )
            }
        },
        revokeTokenTypes: ['refresh_token'],
        automaticSilentRenew: false,
        metadataSeed: {
            end_session_endpoint: `${authority}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(origin)}`,
            jwks_uri: `https://cognito-idp.${region}.amazonaws.com/${region}_${poolId}/.well-known/jwks.json`,
            issuer: `https://cognito-idp.${region}.amazonaws.com/${region}_${poolId}`,
            authorization_endpoint: `${authority}/oauth2/authorize`,
            token_endpoint: `${authority}/oauth2/token`,
            userinfo_endpoint: `${authority}/oauth2/userInfo`,
        },
        metadata: {
            end_session_endpoint: `${authority}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(origin)}`,
            jwks_uri: `https://cognito-idp.${region}.amazonaws.com/${region}_${poolId}/.well-known/jwks.json`,
            issuer: `https://cognito-idp.${region}.amazonaws.com/${region}_${poolId}`,
            authorization_endpoint: `${authority}/oauth2/authorize`,
            token_endpoint: `${authority}/oauth2/token`,
            userinfo_endpoint: `${authority}/oauth2/userInfo`,
        }
    };

    if (typeof window !== 'undefined') {
        oidcConfig.userStore = new WebStorageStateStore({ store: window.localStorage });

    }
    return oidcConfig;
}
